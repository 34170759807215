<i18n>
{
  "de": {
    "portfolios": "Portfolios",
    "deletePendingMessage": "Portfolio wird gelöscht...",
    "noPortfolios": "Keine Portfolios",
    "addPortfolio": "Portfolio erstellen",
    "importPortfolio": "Portfolio importieren",
    "userViewLabel": "Benutzer-Ansicht",
    "deleteConfirmation": "Wollen Sie Portfolio \"{name}\" wirklich löschen",
    "idTitle": "ID",
    "nameTitle": "Name",
    "buildingCountTitle": "Liegenschaften",
    "lastModifiedTitle": "Letzte Änderung"
  }
}
</i18n>

<template>
  <main-layout :ready="allPortfoliosReady" class="admin-portfolios-page">
    <template #default>
      <h1>{{ $t('portfolios') }}</h1>
      <button-wrapper>
        <router-link class="button" :to="{ name: 'adminAddPortfolio' }">{{ $t('addPortfolio') }}</router-link>
        <router-link class="button" :to="{ name: 'adminImportPortfolio' }">{{ $t('importPortfolio') }}</router-link>
      </button-wrapper>
      <p v-if="deleteError">{{ deleteError }}</p>
      <p v-if="deletePending">{{ $t('deletePendingMessage') }}</p>
      <detail-list class="portfolios-list" v-else-if="allPortfolios.length > 0">
        <template #header>
          <span>{{ $t('idTitle') }}</span>
          <span>{{ $t('nameTitle') }}</span>
          <span class="align-right">{{ $t('buildingCountTitle') }}</span>
          <span class="align-right">{{ $t('lastModifiedTitle') }}</span>
          <span class="align-right"></span>
        </template>
        <li v-for="portfolio in allPortfolios" :key="portfolio.id">
          <span>{{ portfolio.id }}</span>
          <router-link :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id }}" class="portfolio-title">
            {{ portfolio.name }}
          </router-link>
          <span class="align-right">{{ portfolio.buildings_count }}</span>
          <span class="align-right">{{ portfolio.last_modified | luxon({output: "full"}) }}</span>
          <list-button-wrapper>
            <list-delete-button @click="onDeletePortfolio(portfolio)" />
          </list-button-wrapper>
        </li>
      </detail-list>
      <p v-else>{{ $t('noPortfolios') }}</p>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import DetailList from '@/components/shared/lists/DetailList'
import ButtonWrapper from '@/components/shared/ButtonWrapper'

import { mapActions } from 'vuex'

export default {
  mixins: [
    AllPortfoliosMixin // Provides: allPortfolios, allPortfoliosReady
  ],
  data () {
    return {
      'deletePending': false,
      'deleteError': null
    }
  },
  methods: {
    ...mapActions({
      deletePortfolio: 'portfolio/deletePortfolio'
    }),
    async onDeletePortfolio (portfolio) {
      const confirmationText = this.$t('deleteConfirmation', { name: portfolio.name })
      if (confirm(confirmationText)) {
        try {
          this.deletePending = true
          await this.deletePortfolio(portfolio.id)
        } catch (error) {
          this.deleteError = error
        }
        this.deletePending = false
      }
    }
  },
  components: {
    ListButtonWrapper,
    ListDeleteButton,
    MainLayout,
    ButtonWrapper,
    DetailList
  }
}
</script>

<style>
.admin-portfolios-page .detail-list.portfolios-list > ul > li {
  grid-template-columns: 60px 3fr 100px 1fr 100px;
}

.admin-portfolios-page .align-right {
  text-align: right;
}
.admin-portfolios-page .portfolio-title {
  font-weight: 600;
}
</style>
